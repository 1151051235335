import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';

import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import "gridjs/dist/theme/mermaid.css";

import Header from './Header';
import Router from "./Router";
import { AppContext } from "./lib/contextLib";
import OverlayLoader from './lib/OverlayLoader';
import { ErrorComponent } from "./lib/errorLib";

import { Container, Row, Col } from 'react-bootstrap';
import {makeGetRequest} from "./lib/requestLib";

function App() {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [accessToken, setAccessToken] = useState(null);
    const [errors, setErrors] = useState([]);
    const history = useNavigate();

    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        setErrors([]);
        try {
            const token = Cookies.get('accessToken');
            await makeGetRequest({ path: `/auth`, accessToken: token });
            setAccessToken(token);
            userHasAuthenticated(true);
        } catch(e) {
            // skip auth error since some pages are public
        }

        setIsAuthenticating(false);
    }

    async function handleLogout() {
        Cookies.remove('accessToken');
        userHasAuthenticated(false);
        setAccessToken('');
        history("/", { replace: true });
    }

    return (
        <Container fluid>
            {!isAuthenticating && (
            <Row>
                <Header isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
                <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, accessToken, setAccessToken, errors, setErrors }}>
                    <Row className='inner-app-container mt-3'>
                        <Col xs={{ span: 2 }} className="error-container ps-4 pe-4">
                            {isEmpty(errors) ? null : errors.map((item, index) => <ErrorComponent error={item} key={`error-message-${index}`} />)}
                        </Col>
                        <Col xs={{ span: 8 }} className="pe-0 ps-0">
                            <Router />
                        </Col>
                    </Row>
                </AppContext.Provider>
            </Row>
            )}
            <OverlayLoader isLoading={isAuthenticating} />
        </Container>
    );
}

export default App;

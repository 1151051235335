// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
// If you want to change the content of this file, you need to edit
// file '/src/i18n/po/en.po' and run '.po' extractor

export const en = {
  'Action data': 'Action data',

  'Add Component': 'Add Component',

  'Add Message': 'Add Message',

  'Add Resource': 'Add Resource',

  'Add State': 'Add State',

  'Add new message': 'Add new message',

  'Add new resource': 'Add new resource',

  'Add resource state': 'Add resource state',

  'Are sure you want to delete?': 'Are sure you want to delete?',

  'Calculate history': 'Calculate history',

  'Edit new message': 'Edit new message',

  'Enter message': 'Enter message',

  'Enter title': 'Enter title',

  'Leave a comment here': 'Leave a comment here',

  'Make message invisible?': 'Make message invisible?',

  'Resources status history': 'Resources status history',

  'Select component': 'Select component',

  'Select resource': 'Select resource',

  'Select status': 'Select status',

  'Sorry, page not found!': 'Sorry, page not found!',

  'View history record': 'View history record',

  action: 'Action',

  actions: 'Actions',

  apply: 'Apply',

  close: 'Close',

  comment: 'Comment',

  component: 'Component',

  components: 'Components',

  date: 'Date',

  fail: 'FAIL',

  history: 'History',

  id: 'ID',

  login: 'Login',

  logout: 'Logout',

  message: 'Message',

  messages: 'Messages',

  name: 'Name',

  ok: 'OK',

  password: 'Password',

  resource: 'Resource',

  resources: 'Resources',

  status: 'Status',

  submit: 'Submit',

  text: 'Text',

  title: 'Title',

  'visibility status': 'Visibility status',

  warning: 'WARNING',

  'An error happened while fetching the data':
    'An error happened while fetching the data',

  Loading: 'Loading',

  'No matching records found': 'No matching records found',

  'Page ${page}': 'Page ${page}',

  'Page ${page} of ${pages}': 'Page ${page} of ${pages}',

  search: 'Search',

  showing: 'Showing',

  previous: 'Previous',

  next: 'Next',

  'Delete of "${title}" resource': 'Delete of "${title}" resource',
};

import React, { useState, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Form, Button } from "react-bootstrap";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { Grid, _ as _h } from 'gridjs-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import ViewModal from './ViewModal';
import OverlayLoader from '../../lib/OverlayLoader';
import { formatDate } from './../../utils';
import { useAppContext } from '../../lib/contextLib';
import { makeGetRequest } from '../../lib/requestLib';


function mapHistoryDataForTable ({ data, onView }) {
    return map(data, (el, index) => ({
        date: formatDate(el.date),
        action: el.action,
        text: el.text,
        message_id: get(el, 'message_id'),
        resource_id: get(el, 'resource_id'),
        component_id: get(el, 'component_id'),
        actions: _h(<Button variant="outline-info" size="sm" className="view-btn" onClick={() => onView(index)}><FontAwesomeIcon icon={faCircleInfo} /></Button>)        
    }));
}

function HistoryList() {
    const now = new Date();
    const [data, setData] = useState([]);
    const [dates, setDates] = useState([now, now]);
    const [reloadTable, setReloadTable] = useState(true);
    const [currentHistoryElement, setCurrentHistoryElement] = useState(null);
    const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
    const [ resources, setResources ] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [resourceId, setResourceId] = useState("");
    const [componentId, setComponentId] = useState("");
    const [components, setComponents] = useState([]);

    const { accessToken, isAuthenticated, setErrors } = useAppContext();
    const intl = useIntl();

    const gridRef = useRef();

    useEffect(() => {
        function loadResources() {
            return makeGetRequest({ path: "/resources", accessToken });
        }

        async function onLoad() {        
            setIsLoading(true);
            try {
                const r = await loadResources();
                setResources(r);
            } catch (e) {
                setErrors(errors => [...errors, e]);
            } finally {
                setIsLoading(false);
            }
        }

        onLoad(); 
    }, [accessToken]);

    const handleResourceSelectorChange = (e) => {
        if (!isEmpty(e.target.value)) {
            setResourceId(e.target.value);
            const currentResource = find(resources, r => r.id === e.target.value);
            setComponents(currentResource.components);
            setComponentId(null);
        } else {
            setResourceId(null);
            setComponents([]);
            setComponentId(null);
        }
    }

    const onChangeDatePicker = async (dates) => {
        setDates(dates);
    };

    const onApplyFilters = async () => {
        setReloadTable(true);
    }
    
    async function loadHistory({ start, end, componentId, resourceId }) {
        let ah = [];
        setIsLoading(true);
        try {
            ah = await makeGetRequest({
                path: `/actions-history`,
                accessToken,
                queryParams: {
                    startDate: start.getTime(),
                    endDate: end.getTime(),
                    componentId, 
                    resourceId
                }
            });
            setData(ah);
        } catch (e) {
            setErrors(errors => [...errors, e]);
        } finally {
            setIsLoading(false)
        }

        return ah;
    }

    const onViewButtonClick = (index) => {
        setCurrentHistoryElement(data[index])
        setViewModalIsOpen(true)
    }

    const loadTableData = async () => {
        if (reloadTable && isAuthenticated) {
            setReloadTable(false);
            await loadHistory({ start: dates[0], end: dates[1], componentId, resourceId });
        }
        return mapHistoryDataForTable({ data, onView: (i) => onViewButtonClick(i) });
    }

    // initial table setup
    const columns = [
        { name: intl.formatMessage({ id: 'date', defaultMessage: 'Date' }), id: 'date' },
        { name: intl.formatMessage({ id: 'action', defaultMessage: 'Action' }), id: 'action' },
        { name: intl.formatMessage({ id: 'message', defaultMessage: 'Message' }), id: 'message_id' },
        { name: intl.formatMessage({ id: 'resource', defaultMessage: 'Resource' }), id: 'resource_id' },
        { name: intl.formatMessage({ id: 'component', defaultMessage: 'Component' }), id: 'component_id' },
        { name: intl.formatMessage({ id: 'text', defaultMessage: 'Text' }), id: 'text' },
        { name: '', id: 'actions', sort: { enabled: false } }
    ];

    return (
        <Row>
            <Col sm={12} className="history-list-filters row">
                <Form id="filters">
                    <Row className="mb-2">
                        <Col sm={4}>
                            <DateRangePicker locale={intl.locale} clearIcon={null} value={dates} maxDate={now} minDetail={"decade"} onChange={(dates) => onChangeDatePicker(dates)} />
                        </Col>
                        <Col className='col-sm-3'>
                            <Form.Group>
                                <Form.Select id="resource_id" onChange={(e) => handleResourceSelectorChange(e)} defaultValue={resourceId}>
                                    <option value="" key={`resource-option-empty`}>{intl.formatMessage({ id: 'Select resource', defaultMessage: 'Select resource' })}</option>
                                    {resources.map((item) => <option value={item.id} key={`resource-option-${item.id}`}>{item.title}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className='col-sm-3'>
                            <Form.Group>
                                <Form.Select id="component_id" onChange={(e) => setComponentId(e.target.value)} defaultValue={componentId}>
                                    <option value="" key={`component-option-empty`}>{intl.formatMessage({ id: 'Select component', defaultMessage: 'Select component' })}</option>
                                    {components.map((item) => <option value={item.id} key={`component-option-${item.id}`}>{item.title}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className="pe-0" sm={{ span: 1, offset: 1 }} >
                            <Button variant="success" onClick={() => onApplyFilters()}><FormattedMessage id="apply" defaultMessage="Apply" /></Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col sm={12} className="history-list-table-wrapper">
                <Grid
                    data={loadTableData}
                    ref={gridRef}
                    columns={columns}
                    sort={true}
                    search={{ enabled: true, ignoreHiddenColumns: false }}
                    pagination={{
                        enabled: true,
                        limit: 8
                    }}
                    language={{
                        loading: `${intl.formatMessage({ id: 'Loading', defaultMessage: 'Loading' })}...`,
                        noRecordsFound: intl.formatMessage({ id: 'No matching records found', defaultMessage: 'No matching records found' }),
                        error: intl.formatMessage({ id: 'An error happened while fetching the data', defaultMessage: 'An error happened while fetching the data' }),
                        search: {
                            placeholder: intl.formatMessage({ id: 'search', defaultMessage: 'Search' })
                        },
                        pagination: {
                            previous: intl.formatMessage({ id: 'previous', defaultMessage: 'Previous' }),
                            next: intl.formatMessage({ id: 'next', defaultMessage: 'Next' }),
                            showing: intl.formatMessage({ id: 'showing', defaultMessage: 'Showing' }),
                            navigate: (page, pages) => intl.formatMessage({ id: 'Page ${page} of ${pages}', defaultMessage: 'Page ${page} of ${pages}', values: { page, pages } }),
                            page: (page) => intl.formatMessage({ id: 'Page ${page}', defaultMessage: 'Page ${page}', values: { page } })
                        }
                    }}
                />
            </Col>

            <ViewModal elem={currentHistoryElement} show={viewModalIsOpen} onHide={() => setViewModalIsOpen(false)} />
            <OverlayLoader isLoading={isLoading} />
        </Row>
    )
}

export default HistoryList;
import { API } from "aws-amplify";
import isEmpty from 'lodash/isEmpty';

export async function makePostRequest({ path, body, accessToken }) {
    const params = {
        headers: { 
            Authorization: `Bearer ${accessToken}`
        },
        body
    };
    return API.post("Operations", path, params);
}

export async function makePutRequest({ path, body, accessToken }) {
    const params = {
        headers: { 
            Authorization: `Bearer ${accessToken}`
        },
        body
    };
    return API.put("Operations", path, params);
}

export async function makeGetRequest({ path, queryParams, accessToken }) {
    const params = {
        queryStringParameters: queryParams
    };
    if (!isEmpty(accessToken)) {
        params.headers = { 
            Authorization: `Bearer ${accessToken}`
        }
    }
    return API.get("Operations", path, params);
}
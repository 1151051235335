import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { Row, Col, Form, Button, ListGroup, Modal } from "react-bootstrap";
import { EditIcon, AddIcon, RemoveIcon } from '../../lib/icons';

import OverlayLoader from '../../lib/OverlayLoader';
import { useAppContext } from '../../lib/contextLib';
import { makePostRequest } from '../../lib/requestLib';
import { ErrorComponent } from '../../lib/errorLib';

function EditModal({ show, onHide, resource }) {
    const initialId = get(resource, 'id', '');
    const initialTitle = get(resource, 'title', '');
    const initialComponents = JSON.stringify(get(resource, 'components', []));

    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [components, setComponents] = useState([]);
    const [componentId, setComponentId] = useState("");
    const [componentTitle, setComponentTitle] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const { accessToken, isAuthenticated } = useAppContext();
    const intl = useIntl()

    useEffect(() => { 
        setId(initialId);
        setTitle(initialTitle);
        setComponents(JSON.parse(initialComponents));
    }, [initialId, initialTitle, initialComponents] )

    function validateForm() {
        return isAuthenticated && title.length > 0
            && (title !== initialTitle || !isEqual(JSON.parse(initialComponents), components));
    }

    const validateComponentForm = () => {
        return componentTitle.length > 0;
    }

    async function editResource(resourceData) {
        return makePostRequest({ path: `/resources/${initialId}`, accessToken, body: resourceData });
    }

    const handleRemoveClick = (i) => {
        const list = [...components];
        list.splice(i, 1);
        setComponents(list);
    }

    const handleAddClick = () => {
        const item = { title: componentTitle };
        if (!isEmpty(componentId)) {
            item.id = componentId;
        }
        setComponents([...components, { ...item }]);

        setComponentId("");
        setComponentTitle("");
    };

    const handleEditClick = (i) => {
        const item = components[i];
        const list = [...components];
        list.splice(i, 1);
        setComponents(list);
        setComponentId(item.id);
        setComponentTitle(item.title);
    }

    async function handleSubmit(event) {      
        event.preventDefault();
        setIsLoading(true);
        setError(null);
        
        try {
            await editResource({
                id,
                title,
                components
            });
            onHide();
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="edit-message-modal" centered >
            <Modal.Header closeButton>
                <Modal.Title id="edit-message-modal"><FormattedMessage id="Edit new message" defaultMessage="Edit new message" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        {error && <Col className='col-sm-12 mb-3'><ErrorComponent error={error} /></Col>}
                        <Col className='col-sm-12 mb-3'>
                            <Form.Group>
                                <Form.Label><FormattedMessage id="title" defaultMessage="Title" /></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder={intl.formatMessage({ id: 'Enter title', defaultMessage: 'Enter title' })} 
                                    id="resource_title" 
                                    value={title} 
                                    onChange={(e) => setTitle(e.target.value)} 
                                />
                            </Form.Group>
                        </Col>
                        <Col className='col-sm-12'>
                            <Col className='col-sm-4 mb-3'>
                                <Button variant="success" size="sm" disabled={!validateComponentForm()} onClick={() => handleAddClick()}><AddIcon /> Add Component</Button>
                            </Col>
                            <Row className="mb-3">
                                <Col className='col-sm-12'>
                                    <Form.Group>
                                        <Form.Label><FormattedMessage id="title" defaultMessage="Title" /></Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder={intl.formatMessage({ id: 'Enter title', defaultMessage: 'Enter title' })} 
                                            id="component_title" 
                                            onChange={(e) => setComponentTitle(e.target.value)}
                                            value={componentTitle}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-sm-10 offset-1 components-list'>
                                    <ListGroup as="ol" numbered>
                                        {components.map((item, index) => {
                                            return (
                                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" key={`component-${index}`}>
                                                    <div className="ms-2 me-auto">
                                                        {item.title}
                                                    </div>
                                                    <Button variant="danger" size="sm" className="me-1" onClick={() => handleRemoveClick(index)}><RemoveIcon /></Button>
                                                    <Button variant="warning" size="sm" onClick={() => handleEditClick(index)}><EditIcon /></Button>
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Col> 
                    </Row>  
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" disabled={!validateForm()} onClick={(e) => handleSubmit(e)}>
                    <FormattedMessage id="submit" defaultMessage="Submit" />
                </Button>
                <Button variant="secondary" onClick={onHide}><FormattedMessage id="close" defaultMessage="Close" /></Button>
            </Modal.Footer>
            <OverlayLoader isLoading={isLoading} />
        </Modal>
    );
}

export default EditModal;
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from "react-intl";

function Dialog({ headerText, bodyText, show, onSubmit, onHide }) {

    return (<Modal show={show} onHide={onHide} size="md" centered >
        <Modal.Header closeButton>
            <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>{bodyText}</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}><FormattedMessage id="close" defaultMessage="Close" /></Button>
            <Button variant="primary" onClick={onSubmit}><FormattedMessage id="ok" defaultMessage="OK" /></Button>
        </Modal.Footer>
    </Modal>)
}

export default Dialog;
import React, { useState } from 'react';
import { Row, Col, Form, Button, ListGroup, Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from 'react-intl';
import { AddIcon, RemoveIcon } from '../../lib/icons';

import OverlayLoader from '../../lib/OverlayLoader';
import { useAppContext } from '../../lib/contextLib';
import { makePutRequest } from '../../lib/requestLib';
import { ErrorComponent } from '../../lib/errorLib';

function AddModal({ show, onHide }) {
    const [title, setTitle] = useState("");
    const [components, setComponents] = useState([]);
    const [componentTitle, setComponentTitle] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const { accessToken, isAuthenticated } = useAppContext();
    const intl = useIntl();

    const validateForm = () => {
        return isAuthenticated && title.length > 0;
    }

    async function addResource(resource) {
        return makePutRequest({ path: `/resources`, accessToken, body: resource });
    }

    const validateComponentForm = () => {
        return componentTitle.length > 0;
    }

    async function handleSubmit(event) {      
        event.preventDefault();
        
        setError(null);
        setIsLoading(true);
        
        try {
            await addResource({
                title,
                components
            });
            setComponents([]);
            onHide();
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handleRemoveClick = (i) => {
        const list = [...components];
        list.splice(i, 1);
        setComponents(list);
    }

    const handleAddClick = () => {
        setComponents([...components, { title: componentTitle }]);
        setComponentTitle("")
    };

    return (
        <Modal show={show} size="lg" onHide={onHide} aria-labelledby="add-resource-modal" centered >
            <Modal.Header closeButton>
                <Modal.Title id="add-resource-modal"><FormattedMessage id="Add new resource" defaultMessage="Add new resource" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        {error && <Col className='col-sm-12 mb-3'><ErrorComponent error={error} /></Col>}
                        <Col className='col-sm-12 mb-2'>
                            <Form.Group>
                                <Form.Label><FormattedMessage id="title" defaultMessage="Title" /></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder={intl.formatMessage({ id: 'Enter title', defaultMessage: 'Enter title' })} 
                                    id="resource_title" 
                                    onChange={(e) => setTitle(e.target.value)} 
                                />
                            </Form.Group>
                        </Col>
                        <Col className='col-sm-12 mt-3'>
                            <Col className='col-sm-4 mb-3'>
                                <Button variant="success" size="sm" disabled={!validateComponentForm()} onClick={() => handleAddClick()}><AddIcon /> <FormattedMessage id="Add Component" defaultMessage="Add Component" /></Button>
                            </Col>
                            <Row className="mb-3">
                                <Col className='col-sm-12'>
                                    <Form.Group>
                                        <Form.Label><FormattedMessage id="title" defaultMessage="Title" /></Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder={intl.formatMessage({ id: 'Enter title', defaultMessage: 'Enter title' })} 
                                            id="component_title" 
                                            onChange={(e) => setComponentTitle(e.target.value)}
                                            value={componentTitle}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-sm-10 offset-1 components-list'>
                                    <ListGroup as="ol" numbered>
                                        {components.map((item, index) => {
                                            return (
                                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" key={`new-component-${index}`}>
                                                    <div className="ms-2 me-auto">
                                                        {item.title}
                                                    </div>
                                                    <Button variant="warning" size="sm" onClick={() => handleRemoveClick(index)}><RemoveIcon /></Button>
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Col>                        
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" disabled={!validateForm()} onClick={(e) => handleSubmit(e)}>
                    <FormattedMessage id="submit" defaultMessage="Submit" />
                </Button>
                <Button variant="secondary" onClick={onHide}><FormattedMessage id="close" defaultMessage="Close" /></Button>
            </Modal.Footer>
            <OverlayLoader isLoading={isLoading} />
        </Modal>
    );
}

export default AddModal;
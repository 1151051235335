import dayjs from 'dayjs';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import castArray from 'lodash/castArray';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import map from 'lodash/map';

import config from './config';
import { en } from './i18n/langs/en/index';
import { pt } from './i18n/langs/pt/index';

function formatDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(date).format(format);
}

function availableStatuses() {
    return [ 'OK', 'WARNING', 'FAIL' ]
}

function loadTranslations() {
    return {
        en,
        pt
    }
}

function getLocale(languages) {
    languages = isArray(languages) ? languages : castArray(languages);
    languages = map(languages, l => l.split('-')[0]);

    const locale = find(languages, lang => {
        return includes(config.i18n.supportedLocales, lang.toLowerCase())
    });
    return isNil(locale) ? config.i18n.defaultLocale : locale.toLowerCase();
}

export {
    formatDate,
    availableStatuses,
    loadTranslations,
    getLocale
}
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import first from 'lodash/first';
import find from 'lodash/find';

import { availableStatuses } from './../../utils';
import OverlayLoader from '../../lib/OverlayLoader';
import { useAppContext } from '../../lib/contextLib';
import { makePostRequest } from '../../lib/requestLib';
import { ErrorComponent } from '../../lib/errorLib';

function AddStateModal({ show, onHide, resources }) {
    const [resourceId, setResourceId] = useState("");
    const [componentId, setComponentId] = useState("");
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const [selectedResource, setSelectedResource] = useState(null);
    const [components, setComponents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const statuses = availableStatuses();

    const { accessToken, isAuthenticated } = useAppContext();
    const intl = useIntl();

    useEffect(() => {
        if (isEmpty(selectedResource)) {
            setSelectedResource(first(resources));
        }
        if (!isEmpty(selectedResource)) {
            setComponents(get(selectedResource, 'components', []));
            setResourceId(selectedResource.id);
            if (!isEmpty(components)) {
                setComponentId(get(components, [0, 'id'], null))
            }
        }
    }, [resources, selectedResource, components])

    const validateForm = () => {
        return isAuthenticated && resourceId && componentId && status.length > 0;
    }

    async function updateState(data) {
        return makePostRequest({ path: `/update-state`, accessToken, body: data });
    }

    async function handleSubmit(event) {      
        event.preventDefault();
        
        setError(null);
        setIsLoading(true);
        
        try {
            await updateState({
                component_id: componentId,
                state: status,
                message
            });
            onHide();
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false)
        }
    }

    const handleResourceSelectorChange = (e) => {
        setResourceId(e.target.value);
        const currentResource = find(resources, r => r.id === e.target.value);
        setSelectedResource(currentResource);
    }

    return (
        <Modal show={show} size="lg" onHide={onHide} aria-labelledby="add-resource-state-modal" centered >
            <Modal.Header closeButton>
                <Modal.Title id="add-resource-state-modal"><FormattedMessage id="Add resource state" defaultMessage="Add resource state" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        {error && <Col className='col-sm-12 mb-3'><ErrorComponent error={error} /></Col>}
                        <Col className='col-sm-4 mb-2'>
                            <Form.Group>
                                <Form.Label><FormattedMessage id="resource" defaultMessage="Resource" /></Form.Label>
                                <Form.Select id="resource_id" onChange={(e) => handleResourceSelectorChange(e)} defaultValue={resourceId}>
                                    {resources.map((item) => <option value={item.id} key={`resource-option-${item.id}`}>{item.title}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className='col-sm-4 mb-2'>
                            <Form.Group>
                                <Form.Label><FormattedMessage id="component" defaultMessage="Component" /></Form.Label>
                                <Form.Select id="component_id" onChange={(e) => setComponentId(e.target.value)} defaultValue={componentId}>
                                    {components.map((item) => <option value={item.id} key={`component-option-${item.id}`}>{item.title}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className='col-sm-4 mb-2'>
                            <Form.Group className="mb-3">
                                <Form.Label><FormattedMessage id="status" defaultMessage="Status" /></Form.Label>
                                <Form.Select id="status" onChange={(e) => setStatus(e.target.value) }>
                                    <option>{intl.formatMessage({ id: 'Select status', defaultMessage: 'Select status' })}</option>
                                    {statuses.map((item) => <option value={item} key={`status-option-${item}`}>{item}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>                        
                        <Col className='col-sm-12'>
                            <Form.Group>
                                <Form.Label><FormattedMessage id="message" defaultMessage="Message" /></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    id="message"
                                    placeholder={intl.formatMessage({ id: 'Leave a comment here', defaultMessage: 'Leave a comment here' })}
                                    onChange={(e) => setMessage(e.target.value)}
                                    style={{ height: '100px' }}
                                />
                            </Form.Group>
                        </Col>                 
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" disabled={!validateForm()} onClick={(e) => handleSubmit(e)}>
                    <FormattedMessage id="submit" defaultMessage="Submit" />
                </Button>
                <Button variant="secondary" onClick={onHide}><FormattedMessage id="close" defaultMessage="Close" /></Button>
            </Modal.Footer>
            <OverlayLoader isLoading={isLoading} />
        </Modal>
    );
}

export default AddStateModal;
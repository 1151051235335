// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
// If you want to change the content of this file, you need to edit
// file '/src/i18n/po/pt.po' and run '.po' extractor

export const pt = {
  'Action data': 'Dados de ação',

  'Add Component': 'Adicionar Componente',

  'Add Message': 'Adicionar Mensagem',

  'Add Resource': 'Adicionar Recurso',

  'Add State': 'Adicionar Estado',

  'Add new message': 'Adicionar nova mensagem',

  'Add new resource': 'Adicionar novo recurso',

  'Add resource state': 'Adicionar estado do recurso',

  'An error happened while fetching the data':
    'Ocorreu um erro ao buscar os dados',

  'Are sure you want to delete?': 'Tem certeza de que deseja excluir?',

  'Calculate history': 'Calcular histórico',

  'Delete of "${title}" resource': 'Excluir recurso de "${title}"',

  'Edit new message': 'Editar nova mensagem',

  'Enter message': 'Digite a mensagem',

  'Enter title': 'Digite o título',

  'Leave a comment here': 'Deixe um comentário aqui',

  Loading: 'Carregando',

  'Make message invisible?': 'Tornar a mensagem invisível?',

  'No matching records found': 'Nenhum registro correspondente encontrado',

  'Page ${page}': 'Pagina ${page}',

  'Page ${page} of ${pages}': 'Pagina ${page} of ${pages}',

  'Resources status history': 'Histórico de status dos recursos',

  'Select component': 'Selecionar componente',

  'Select resource': 'Selecionar recurso',

  'Select status': 'Selecionar status',

  'Sorry, page not found!': 'Desculpe, página não encontrada!',

  'View history record': 'Ver registro do histórico',

  action: 'Ação',

  actions: 'Ações',

  apply: 'Aplicar',

  close: 'Perto',

  comment: 'Comente',

  component: 'Componente',

  components: 'Componentes',

  date: 'Data',

  fail: 'FALHA',

  history: 'História',

  id: 'ID',

  login: 'Login',

  logout: 'Sair',

  message: 'Mensagem',

  messages: 'Mensagens',

  name: 'Nome',

  next: 'Próximo',

  ok: 'OK',

  password: 'Senha',

  previous: 'Anterior',

  resource: 'Recurso',

  resources: 'Recursos',

  search: 'Procurar',

  showing: 'Mostrando',

  status: 'Status',

  submit: 'Enviar',

  text: 'Texto',

  title: 'Título',

  today: 'Hoje',

  'visibility status': 'Status de visibilidade',

  warning: 'AVISO',

  'Processing pipeline': 'Processamento',

  Database: 'Banco de Dados',

  Application: 'Aplicação',

  'LoRa Network': 'Rede LoRa',

  'Server is operational': 'Servidor Operacional',

  'Observable state': 'Observável',

  'Devices online': 'Dispositivos Online',

  'Incoming data': 'Dados chegando',

  'UI is operational': 'Sistema em operação',

  'MQTT Transport': 'MQTT',

  'Key processing metrics': 'Métricas chave de processamento',

  'Absence of unusual behaviors': 'Ausência de comportamentos incomuns',

  'Load is under control': 'Carga de trabalho',
};

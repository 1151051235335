import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck, faCircleExclamation, faCircleQuestion, faPen, faPlus, faCircleMinus, faEye, faEyeSlash, faCircleInfo, faStar } from "@fortawesome/free-solid-svg-icons";

library.add(
    faCircleCheck,
    faCircleExclamation,
    faCircleQuestion,
    faPen,
    faPlus,
    faCircleMinus,
    faEye, 
    faEyeSlash,
    faCircleInfo,
    faStar
);
import React from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";

import Main from "./pages/main/index";
import NotFound from "./pages/NotFound";
import Login from "./pages/login/Login";
import MessagesList from "./pages/messages";
import ResourcesList from "./pages/resources";
import HistoryList from './pages/history';
import { useAppContext } from "./lib/contextLib";

const PrivateRoute = () => {
    const { isAuthenticated } = useAppContext();

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

export default function Router() {
    return (
        <Routes>
            <Route exact path="/login" element={<Login/>} />
            <Route exact path='/' element={<Main/>}/>
            <Route exact path='/messages' element={<PrivateRoute/>}>
                <Route exact path="/messages" element={<MessagesList />} />
            </Route>
            <Route exact path='/resources' element={<PrivateRoute/>}>
                <Route exact path="/resources" element={<ResourcesList />} />
            </Route>
            <Route exact path='/history' element={<PrivateRoute/>}>
                <Route exact path="/history" element={<HistoryList />} />
            </Route>
            <Route element={<NotFound/>} />
        </Routes>
    );
}
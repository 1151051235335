import React, { useState, useEffect } from 'react';
import { Row, ListGroup, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import isEmpty from 'lodash/isEmpty';

import { formatDate } from './../../utils';
import { StatusIcon } from './../../lib/icons';
import ComponentLoader from '../../lib/ComponentLoader';
import { useAppContext } from '../../lib/contextLib';
import { makeGetRequest } from '../../lib/requestLib';

function Message({ message }) {
    return (
        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
                {isEmpty(message.state) ? null : <StatusIcon status={message.state} />}
                <div className="fw-bold">{message.message}</div>
                {formatDate(message.date)}
            </div>
        </ListGroup.Item>
    );
}

function Messages() {
    const [ messages, setMessages ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const { accessToken, setErrors } = useAppContext();

    useEffect(() => {
        function loadMessages() {
            return makeGetRequest({ path: "/messages", accessToken, queryParams: { today: 1, onlyVisible: 1 } });
        }

        async function onLoad() {        
            setIsLoading(true);
            try {
                const messages = await loadMessages();
                setMessages(messages);
            } catch (e) {
                // console.log('Message', e.response)
                setErrors(errors => [...errors, e]);
            } finally {
                setIsLoading(false);
            }
        }

        onLoad(); 
    }, [accessToken]);

    return (
        <Row className="mb-4 col-sm-12 pe-0 component-loader-wrapper">
            <Col sm="12" className="pe-0">
                {messages.length > 0 && <h4><FormattedMessage id="messages" defaultMessage="Messages" /></h4>}
            </Col>
            <Col sm="12" className="messages-list pe-0">
                <ListGroup as="ol" numbered>
                    {messages.map((item, index) => <Message key={`message-${index}`} message={item}></Message>)}
                </ListGroup>
            </Col>
            <ComponentLoader isLoading={isLoading} />
        </Row>
    );
}

export default Messages;
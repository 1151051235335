import React, { useState, useEffect } from 'react';
import { Row, Col, Table } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs from 'dayjs';

import { StatusIcon } from '../../lib/icons';
import { formatDate } from '../../utils';
import ComponentLoader from '../../lib/ComponentLoader';
import { makeGetRequest } from '../../lib/requestLib';
import { useAppContext } from '../../lib/contextLib';

function ComponentStatus({ component, intl }) {
    const title = intl.formatMessage({ id: component.title, defaultMessage: component.title });
    return (
        <Row>
            <Col>{title}</Col>
            {component.state && <Col><StatusIcon status={component.state} /></Col>}
        </Row>
    );
}

function StatusTableRow({ resource, rowIndex, dateColumns, intl }) {
    const title = intl.formatMessage({ id: resource.title, defaultMessage: resource.title });
    return (
        <tr>
            <td className="align-center-cell">
                <Row>
                    <Col>{title}</Col>
                    {resource.state && <Col><StatusIcon status={resource.state}/></Col>}
                </Row>
            </td>
            <td>
                {resource.components.map((item) => {
                    return <ComponentStatus key={`${item.id}`} component={item} intl={intl}></ComponentStatus>
                })}
            </td>
            {dateColumns.map((item, index) => <td className="align-center-cell" key={`date-cell-${rowIndex}-${index}`}>{resource.history[item.date] && <StatusIcon status={resource.history[item.date]} />}</td>)}
        </tr>
    );
}

function StatusTable({ dateColumns, data, intl }) {
    return (
        <div className="status-table">
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th><FormattedMessage id="resource" defaultMessage="Resource" /></th>
                    <th><FormattedMessage id="components" defaultMessage="Components" /></th>
                    {dateColumns.map((item, index) => <th key={`date-column-${index}`}>{item.label}</th>)}
                </tr>
                </thead>
                <tbody>
                {data.map((resource, index) => {
                    return <StatusTableRow resource={resource} key={`status-table-row-${index}`} dateColumns={dateColumns} intl={intl} />
                })}
                </tbody>
            </Table>
        </div>
    );
}

function ComponentsHistory() {
    const dateColumnsNumber = 7;
    let today = dayjs().add(dayjs().utcOffset(), 'minutes').startOf('day');
    const startDate = today.subtract(dateColumnsNumber, 'days').valueOf();

    const [ componentsHistory, setComponentsHistory ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const { accessToken, setErrors } = useAppContext();
    const intl = useIntl();

    useEffect(() => {
        setIsLoading(true);
        makeGetRequest({
            path:`/components-history`,
            queryParams: { startDate: startDate },
            accessToken
        })
            .then(setComponentsHistory)
            .catch(e => setErrors(errors => [...errors, e]))
            .finally(() => setIsLoading(false))
    }, [startDate, accessToken]);

    const columns = [];
    for(let day = today; day.valueOf() !== startDate; day = day.subtract(1, 'day')) {
        const date = formatDate(day, 'YYYY-MM-DD');
        columns.push({
            date,
            label: day.valueOf() === today.valueOf() ? intl.formatMessage({ id: 'today', defaultMessage: 'Today' }) : date
        });
    }

    return (
        <Row className="col-sm-12 pe-0 mt-2 component-loader-wrapper mb-4">
            <Col sm="12" className="pe-0">
                <h5><FormattedMessage id="Resources status history" defaultMessage="Resources status history" /></h5>
            </Col>
            <Col className="col-sm-12 pe-0">
                <StatusTable data={componentsHistory} dateColumns={columns} intl={intl}></StatusTable>
            </Col>
            <ComponentLoader isLoading={isLoading} />
        </Row>
    );
}

export default ComponentsHistory;

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import get from 'lodash/get';

import './index.css';
import App from './App';

import './fontawesome';
import config from './config';
import { loadTranslations, getLocale } from './utils';


Amplify.configure({
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        // identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            {
                name: config.app.name,
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            }
        ]
    }
});

const defaultLocale = config.i18n.defaultLocale;
const locale = getLocale(navigator.languages); // language without region code
const messages = loadTranslations();

ReactDOM.render(
    <IntlProvider locale={locale} defaultLocale={defaultLocale} messages={get(messages, locale, messages[defaultLocale])}>
        <React.StrictMode>
            <Router>
                <App />
            </Router>
        </React.StrictMode>
    </IntlProvider>,
    document.getElementById('root')
);


import React, { useState, useRef } from 'react';
import { Row, Col, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Grid, _ as _h } from 'gridjs-react';

import EditModal from './EditModal';
import AddModal from './AddModal';
import OverlayLoader from '../../lib/OverlayLoader';
import { AddIcon, EditIcon, StatusIcon } from '../../lib/icons';
import { formatDate } from './../../utils';
import { makeGetRequest } from '../../lib/requestLib';
import { useAppContext } from '../../lib/contextLib';


function mapMessagesDataForTable ({ data, onEdit }) {
    return map(data, (el, index) => ({
        date: formatDate(el.date),
        hidden_state: el.state,
        state: isEmpty(el.state) ? null : _h(<StatusIcon status={el.state} />),
        message: el.message,
        visibility_status: el.visibility_status ? _h(<FontAwesomeIcon icon={faEye} />) : _h(<FontAwesomeIcon icon={faEyeSlash} />),
        actions: _h(<Button variant="outline-success" className="edit-btn" onClick={() => onEdit(index)}><EditIcon /></Button>)
    }));
}

function MessagesList() {
    const now = new Date();
    const [data, setData] = useState([]);
    const [dates, setDates] = useState([now, now]);
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [reloadTable, setReloadTable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { accessToken, isAuthenticated, setErrors } = useAppContext();
    const intl = useIntl();

    const gridRef = useRef();

    const onEditButtonClick = (index) => {
        setCurrentMessage(data[index])
        setEditModalIsOpen(true)
    }

    async function loadMessages({ start, end }) {
        let messages = [];
        setIsLoading(true);
        try {
            messages = await makeGetRequest({
                path: `/messages`, 
                accessToken,
                queryParams: {
                    startDate: start.getTime(),
                    endDate: end.getTime()
                }
            });
            setData(messages);
        } catch (e) {
            setErrors(errors => [...errors, e]);
        } finally {
            setIsLoading(false);
        }

        return messages;
    }

    const onChangeDatePicker = async (dates) => {
        setDates(dates);
    };

    const onApplyFilters = async () => {
        setReloadTable(true);
    }

    const loadTableData = async () => {
        if (reloadTable && isAuthenticated) {
            setReloadTable(false);
            await loadMessages({ start: dates[0], end: dates[1] });
        }
        return mapMessagesDataForTable({ data, onEdit: (i) => onEditButtonClick(i) });
    }

    // initial table setup
    const columns = [
        { name: intl.formatMessage({ id: 'date', defaultMessage: 'Date' }), id: 'date' },
        { name: intl.formatMessage({ id: 'status', defaultMessage: 'Status' }), id: 'state' },
        { name: intl.formatMessage({ id: 'text', defaultMessage: 'Text' }), id: 'message' },
        { name: intl.formatMessage({ id: 'visibility status', defaultMessage: 'Visibility status' }), id: 'visibility_status' },
        { name: intl.formatMessage({ id: 'actions', defaultMessage: 'Actions' }), id: 'actions', sort: { enabled: false } },
        // hidden columns for search
        { name: 'Initial state', id: 'hidden_state', hidden: true }
    ];

    const onHideAddModal = () => {
        setAddModalIsOpen(false);
        setReloadTable(true);
    }

    const onHideEditModal = () => {
        setEditModalIsOpen(false);
        setReloadTable(true);
    }

    return (
        <Row>
            <Col sm={12} className="messages-list-filters mb-3">
                <Row className="mb-4">
                    <Col sm={3}>
                        <Button variant="primary" onClick={() => setAddModalIsOpen(true)}><AddIcon /> <FormattedMessage id="Add Message" defaultMessage="Add Message" /></Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        <DateRangePicker locale={intl.locale} clearIcon={null} value={dates} maxDate={now} minDetail={"decade"} onChange={(dates) => onChangeDatePicker(dates)} />
                    </Col>
                    <Col className="pe-0" sm={{ span: 1, offset: 8 }} >
                        <Button variant="success" onClick={() => onApplyFilters()}><FormattedMessage id="apply" defaultMessage="Apply" /></Button>
                    </Col>
                </Row>
            </Col>
            <Col sm={12} className="messages-list-table-wrapper">
                <Grid
                    data={loadTableData}
                    ref={gridRef}
                    columns={columns}
                    sort={true}
                    search={{ enabled: true, ignoreHiddenColumns: false }}
                    pagination={{
                        enabled: true,
                        limit: 10
                    }}
                    language={{
                        loading: `${intl.formatMessage({ id: 'Loading', defaultMessage: 'Loading' })}...`,
                        noRecordsFound: intl.formatMessage({ id: 'No matching records found', defaultMessage: 'No matching records found' }),
                        error: intl.formatMessage({ id: 'An error happened while fetching the data', defaultMessage: 'An error happened while fetching the data' }),
                        search: {
                            placeholder: intl.formatMessage({ id: 'search', defaultMessage: 'Search' })
                        },
                        pagination: {
                            previous: intl.formatMessage({ id: 'previous', defaultMessage: 'Previous' }),
                            next: intl.formatMessage({ id: 'next', defaultMessage: 'Next' }),
                            showing: intl.formatMessage({ id: 'showing', defaultMessage: 'Showing' }),
                            navigate: (page, pages) => intl.formatMessage({ id: 'Page ${page} of ${pages}', defaultMessage: 'Page ${page} of ${pages}', values: { page, pages } }),
                            page: (page) => intl.formatMessage({ id: 'Page ${page}', defaultMessage: 'Page ${page}', values: { page } })
                        }
                    }}
                />
            </Col>

            <AddModal show={addModalIsOpen} onHide={() => onHideAddModal()} />
            <EditModal message={currentMessage} show={editModalIsOpen} onHide={() => onHideEditModal()} />

            <OverlayLoader isLoading={isLoading} />
        </Row>
    )
}

export default MessagesList;
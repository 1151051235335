import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie';

import OverlayLoader from '../../lib/OverlayLoader';
import { useAppContext } from "../../lib/contextLib";
import "./Login.css";
import { makeGetRequest } from "../../lib/requestLib";

export default function Login() {
    const { setAccessToken, userHasAuthenticated, setErrors } = useAppContext();
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordFieldType, setPasswordFieldType] = useState('password');

    const history = useNavigate();

    function validateForm() {
        return password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            await makeGetRequest({ path: `/auth`, accessToken: password });
            Cookies.set('accessToken', password, { expires: 31, path: '/' })
            setAccessToken(password);
            userHasAuthenticated(true);
            history("/", { replace: true });
        } catch (e) {
            setErrors(errors => [...errors, e]);
        } finally {
            setIsLoading(false);
        }
    }

    const handleShowPassword = () => {
        setPasswordFieldType(showPassword ? 'password' : 'text');
        setShowPassword(!showPassword);
    }

    return (
        <div className="login">
            <Form onSubmit={handleSubmit}>
                <Form.Group size="lg" controlId="password" className="mb-3">
                    <Form.Label><FormattedMessage id="password" defaultMessage="Password" /></Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type={passwordFieldType}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputGroup.Text>
                            <FontAwesomeIcon onClick={() => handleShowPassword() } icon={showPassword ? faEyeSlash : faEye} />
                        </InputGroup.Text>
                    </InputGroup>
                    
                </Form.Group>
                <Button type="submit" disabled={!validateForm()}><FormattedMessage id="login" defaultMessage="Login" /></Button>
            </Form>
            <OverlayLoader isLoading={isLoading} />
        </div>
    );
}
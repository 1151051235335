import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCircleCheck, faCircleExclamation, faCircleQuestion, faPen, faCircleMinus } from "@fortawesome/free-solid-svg-icons";

function AddIcon() {
    return (<FontAwesomeIcon icon={faPlus} className="add-icon" />);
}

function RemoveIcon() {
    return (<FontAwesomeIcon icon={faCircleMinus} className="remove-icon" />);
}

function EditIcon() {
    return (<FontAwesomeIcon icon={faPen} className="edit-icon" />);
}

function StatusIcon({ status }) {
    return (
        status === 'OK' 
            ? <FontAwesomeIcon icon={faCircleCheck} className="status-icon status-icon-ok" /> 
            : status === 'FAIL' 
                ? <FontAwesomeIcon icon={faCircleExclamation} className="status-icon status-icon-fail" /> 
                : <FontAwesomeIcon icon={faCircleExclamation} className="status-icon status-icon-warning" />
    );
}

export { AddIcon, EditIcon, StatusIcon, RemoveIcon }
import React from 'react';
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import Messages from './Messages';
import ComponentsHistory from './History';
import { StatusIcon } from './../../lib/icons';

function Main() {
    return (
        <Row className="mb-2">
            <Messages />
            <Col sm={12} className="mt-2 mb-4 row ps-0 pe-0 text-center">
                <Col sm={4} className="fw-bold"><FormattedMessage id="ok" defaultMessage="OK" /> - <StatusIcon status="OK" /></Col>
                <Col sm={4} className="fw-bold"><FormattedMessage id="warning" defaultMessage="WARNING" /> - <StatusIcon status="WARNING" /></Col>
                <Col sm={4} className="fw-bold"><FormattedMessage id="fail" defaultMessage="FAIL" /> - <StatusIcon status="FAIL" /></Col>
            </Col>
            <ComponentsHistory />
        </Row>         
    );
}

export default Main;
import React, { useState, useRef } from 'react';
import { Row, Col, Form, Button, ButtonGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { EditIcon, AddIcon, RemoveIcon } from '../../lib/icons';
import map from 'lodash/map';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { Grid, _ as _h } from 'gridjs-react';

import AddModal from './AddModal';
import EditModal from './EditModal';
import AddStateModal from './AddStateModal';
import Dialog from '../../lib/Dialog';
import OverlayLoader from '../../lib/OverlayLoader';
import { useAppContext } from '../../lib/contextLib';
import { makeGetRequest, makePostRequest } from '../../lib/requestLib';

function mapResourcesDataForTable ({ data, onEdit, onDelete }) {
    return map(data, (el, index) => ({
        title: el.title,
        components: _h(<ul>{get(el, 'components', []).map((item) => <li key={`component-${item.id}`}>{item.title} ({item.id})</li>)}</ul>),
        actions: _h(<>
            <Button variant="outline-success" className="edit-btn" onClick={() => onEdit(index)}><EditIcon /></Button>
            <Button variant="outline-danger" className="remove-btn ms-2" onClick={() => onDelete(index)}><RemoveIcon /></Button>
            </>
        )
    }));
}

function ResourcesList() {
    const [resources, setResources] = useState([]);
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [currentResource, setCurrentResource] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteDialogHeaderText, setDeleteDialogHeaderText] = useState('');
    const [addStateModalIsOpen, setAddStateModalIsOpen] = useState(false);
    const [reloadTable, setReloadTable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { accessToken, isAuthenticated, setErrors } = useAppContext();
    const intl = useIntl()

    const onHideAddModal = () => {
        setAddModalIsOpen(false);
        setReloadTable(true);
    }

    const onHideEditModal = () => {
        setEditModalIsOpen(false);
        setReloadTable(true);
    }

    const gridRef = useRef();

    const onEditButtonClick = (i) => {
        setCurrentResource(resources[i])
        setEditModalIsOpen(true)
    }

    const onDeleteButtonClick = (i) => {
        setCurrentResource(resources[i]);
        setDeleteDialogHeaderText(intl.formatMessage({ id: 'Delete of "${title}" resource', defaultMessage: 'Delete of "${title}" resource', values: { title: resources[i].title } }));
        setShowDeleteDialog(true);
    }

    async function deleteResource(id) {
        return makePostRequest({ path: `/resources-delete/${id}`, accessToken });
    }

    const onConfirmDelete = async () => {
        setShowDeleteDialog(false);

        setIsLoading(true)
        try {
            await deleteResource(currentResource.id);
            setCurrentResource(null);

            const rIndex = findIndex(resources, r => r.id === currentResource.id);
            if (rIndex > -1) {
                const list = [...resources];
                list.splice(rIndex, 1);
                setResources(list);
            }
        } catch (e) {
            setErrors(errors => [...errors, e]);
        } finally {
            setIsLoading(false);
        }
    }

    const onHistoryCalculatePressed = async () => {
        setIsLoading(true)
        try {
            await makeGetRequest({ path: `/calculate-state`, accessToken });
        } catch (e) {
            setErrors(errors => [...errors, e]);
        } finally {
            setIsLoading(false);
        }
    }

    async function loadResources() {
        let r = [];
        setIsLoading(true);
        try {
            r = await makeGetRequest({ path: `/resources`, accessToken });
            setResources(r);
        } catch (e) {
            setErrors(errors => [...errors, e]);
        } finally {
            setIsLoading(false);
        }

        return r;
    }

    const loadTableData = async () => {
        if (reloadTable && isAuthenticated) {
            setReloadTable(false);
            await loadResources();
        }
        return mapResourcesDataForTable({ 
            data: resources, 
            onEdit: (i) => onEditButtonClick(i),
            onDelete: (i) => onDeleteButtonClick(i)
        });
    }

    // initial table setup
    const columns = [
        { name: intl.formatMessage({ id: 'name', defaultMessage: 'Name' }), id: 'title' },
        { name: intl.formatMessage({ id: 'components', defaultMessage: 'Components' }), id: 'components', sort: { enabled: false } },
        { name: intl.formatMessage({ id: 'actions', defaultMessage: 'Actions' }), id: 'actions', sort: { enabled: false } }
    ];

    return (
        <Row>
            <Col sm={12} className="resources-list-filters">
                <Form id="filters">
                    <Row>
                        <Col sm={10} xlg={5}>
                            <ButtonGroup className="mb-3">
                                <Button variant="primary" onClick={() => setAddModalIsOpen(true)}><AddIcon />
                                    <FormattedMessage id="Add Resource" defaultMessage="Add Resource" />
                                </Button>
                                <Button variant="info" onClick={() => setAddStateModalIsOpen(true)}><AddIcon />
                                    <FormattedMessage id="Add State" defaultMessage="Add State" />
                                </Button>
                                <Button variant="danger" onClick={() => onHistoryCalculatePressed()}>
                                    <FormattedMessage id="Calculate history" defaultMessage="Calculate history" />
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col sm={12} className="resources-list-table-wrapper">
                <Grid
                    data={loadTableData}
                    ref={gridRef}
                    columns={columns}
                    sort={true}
                    search={{ enabled: true, ignoreHiddenColumns: false }}
                    pagination={{
                        enabled: true,
                        limit: 10
                    }}
                    language={{
                        loading: `${intl.formatMessage({ id: 'Loading', defaultMessage: 'Loading' })}...`,
                        noRecordsFound: intl.formatMessage({ id: 'No matching records found', defaultMessage: 'No matching records found' }),
                        error: intl.formatMessage({ id: 'An error happened while fetching the data', defaultMessage: 'An error happened while fetching the data' }),
                        search: {
                            placeholder: intl.formatMessage({ id: 'search', defaultMessage: 'Search' })
                        },
                        pagination: {
                            previous: intl.formatMessage({ id: 'previous', defaultMessage: 'Previous' }),
                            next: intl.formatMessage({ id: 'next', defaultMessage: 'Next' }),
                            showing: intl.formatMessage({ id: 'showing', defaultMessage: 'Showing' }),
                            navigate: (page, pages) => intl.formatMessage({ id: 'Page ${page} of ${pages}', defaultMessage: 'Page ${page} of ${pages}', values: { page, pages } }),
                            page: (page) => intl.formatMessage({ id: 'Page ${page}', defaultMessage: 'Page ${page}', values: { page } })
                        }
                    }}
                />
            </Col>

            <AddModal show={addModalIsOpen} onHide={() => onHideAddModal()} />
            <AddStateModal show={addStateModalIsOpen} onHide={() => setAddStateModalIsOpen(false)} resources={resources} />
            <EditModal resource={currentResource} show={editModalIsOpen} onHide={() => onHideEditModal()} />
            <Dialog 
                show={showDeleteDialog} 
                bodyText={intl.formatMessage({ id: 'Are sure you want to delete?', defaultMessage: 'Are sure you want to delete?' })}
                headerText={deleteDialogHeaderText} 
                onSubmit={onConfirmDelete} 
                onHide={() => setShowDeleteDialog(false)} 
            />
            <OverlayLoader isLoading={isLoading} />
        </Row>
    )
}

export default ResourcesList;
import React, { useState } from 'react';
import { Form, Button, Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import OverlayLoader from '../../lib/OverlayLoader';
import { availableStatuses } from './../../utils';
import { useAppContext } from '../../lib/contextLib';
import { makePutRequest } from '../../lib/requestLib';
import { ErrorComponent } from '../../lib/errorLib';
import isNil from "lodash/isNil";

function AddModal({ show, onHide }) {
    const [text, setText] = useState("");
    const [status, setStatus] = useState(null);
    const [visibility_status, setVisibilityStatus] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const { accessToken, isAuthenticated } = useAppContext();
    const intl = useIntl();

    const statuses = availableStatuses();

    function validateForm() {
        return isAuthenticated && text.length > 0;
    }

    async function addMessage(message) {
        return makePutRequest({ path: `/messages`, accessToken, body: message });
    }

    async function handleSubmit(event) {      
        event.preventDefault();        
        setIsLoading(true);
        
        try {
            await addMessage({
                message: text,
                state: status,
                visibility_status
            });
            onHide();
        } catch (e) {
            setError(e);
        } finally {
             setIsLoading(false);
        }
    }

    return (
        <Modal show={show} size="lg" onHide={onHide} aria-labelledby="add-message-modal" centered >
            <Modal.Header closeButton>
                <Modal.Title id="add-message-modal"><FormattedMessage id="Add new message" defaultMessage="Add new message" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isNil(error) ? null : <ErrorComponent error={error} />}
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label><FormattedMessage id="message" defaultMessage="Message" /></Form.Label>
                        <Form.Control 
                            as="textarea" 
                            placeholder={intl.formatMessage({ id: 'Enter message', defaultMessage: 'Enter message' })}
                            id="message_text" 
                            onChange={(e) => setText(e.target.value)}
                            style={{ height: '100px' }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><FormattedMessage id="status" defaultMessage="Status" /></Form.Label>
                        <Form.Select id="message_status" onChange={(e) => { setStatus(e.target.value)} }>
                            <option></option>
                            {statuses.map((item) => <option value={item} key={`status-option-${item}`}>{item}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check 
                            type="checkbox" 
                            label={<FormattedMessage id="Make message invisible?" defaultMessage="Make message invisible?" />}
                            id="message_visibitily_state" 
                            onChange={(e) => { setVisibilityStatus(!e.target.checked)} }
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" disabled={!validateForm()} onClick={(e) => handleSubmit(e)}>
                    <FormattedMessage id="submit" defaultMessage="Submit" />
                </Button>
                <Button variant="secondary" onClick={onHide}><FormattedMessage id="close" defaultMessage="Close" /></Button>
            </Modal.Footer>
            <OverlayLoader isLoading={isLoading} />
        </Modal>
    );
}

export default AddModal;
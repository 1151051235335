import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import OverlayLoader from '../../lib/OverlayLoader';
import { availableStatuses } from './../../utils';
import { useAppContext } from '../../lib/contextLib';
import { makePostRequest } from '../../lib/requestLib';
import { ErrorComponent } from '../../lib/errorLib';

function EditModal({ show, onHide, message }) {
    const initialMessage = get(message, 'message', '');
    const initialStatus = get(message, 'state');
    const initialVisibilityStatus = get(message, 'visibility_status', true);

    const [text, setText] = useState('');
    const [status, setStatus] = useState('');
    const [visibility_status, setVisibilityStatus] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const { accessToken, isAuthenticated } = useAppContext();
    const intl = useIntl();

    useEffect(() => { 
        setText(initialMessage);
        setStatus(initialStatus);
        setVisibilityStatus(initialVisibilityStatus);
    }, [initialMessage, initialStatus, initialVisibilityStatus] )

    const statuses = availableStatuses();

    function validateForm() {
        return isAuthenticated && text.length > 0 && (text !== initialMessage || status !== initialStatus || visibility_status !== initialVisibilityStatus);
    }

    async function editMessage(messageData) {
        return makePostRequest({ path: `/messages/${message.id}`, accessToken, body: messageData });
    }

    async function handleSubmit(event) {      
        event.preventDefault();
        setIsLoading(true);
        
        try {
            await editMessage({
                id: message.id,
                message: text,
                state: status,
                visibility_status
            });
            onHide();
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="edit-message-modal" centered >
            <Modal.Header closeButton>
                <Modal.Title id="edit-message-modal"><FormattedMessage id="Edit new message" defaultMessage="Edit new message" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isNil(error) ? null : <ErrorComponent error={error} />}
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label><FormattedMessage id="message" defaultMessage="Message" /></Form.Label>
                        <Form.Control 
                            as="textarea" 
                            placeholder={intl.formatMessage({ id: 'Enter message', defaultMessage: 'Enter message' })}
                            value={text} 
                            id="message_text" 
                            onChange={(e) => setText(e.target.value)}
                            style={{ height: '100px' }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><FormattedMessage id="status" defaultMessage="Status" /></Form.Label>
                        <Form.Select id="message_status" onChange={(e) => { setStatus(e.target.value)} } defaultValue={status}>
                            <option>{intl.formatMessage({ id: 'Select status', defaultMessage: 'Select status' })}</option>
                            {statuses.map((item) => <option value={item} key={`status-option-${item}`}>{item}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check 
                            type="checkbox" 
                            label={<FormattedMessage id="Make message invisible?" defaultMessage="Make message invisible?" />}
                            id="message_visibitily_state" 
                            defaultChecked={!visibility_status}
                            onChange={(e) => { setVisibilityStatus(!e.target.checked)} }
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" disabled={!validateForm()} onClick={(e) => handleSubmit(e)}>
                    <FormattedMessage id="submit" defaultMessage="Submit" />
                </Button>
                <Button variant="secondary" onClick={onHide}><FormattedMessage id="close" defaultMessage="Close" /></Button>
            </Modal.Footer>
            <OverlayLoader isLoading={isLoading} />
        </Modal>
    );
}

export default EditModal;
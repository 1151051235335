import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import isNil from 'lodash/isNil';
import get from 'lodash/get';

const formatError = (error) => {
    if (!(error instanceof Error)) {
        const formatted = {};
        if (error === 'No current user') {
            // aws-amplify auth error
            formatted.title = 'Auth failed';
        }
        formatted.message = error.toString()
        return formatted;
    }
    if (!isNil(error.response)) {
        // aws-amplify API error
        return {
            title: 'API error',
            message: get(error.response, ['data', 'error'])
        }
    }

    return error
}
export function ErrorComponent({ error }) {
    const [ showError, setShowError ] = useState(true);

    error = formatError(error);

    return showError
        ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible >
            {isNil(error.title) ? null : <Alert.Heading>{error.title}</Alert.Heading>}
            <p>{error.message}</p>
        </Alert>
        : null;
}
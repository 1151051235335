import React, { useState, useEffect } from 'react';
import { Modal, Accordion, Row, Col } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import OverlayLoader from '../../lib/OverlayLoader';
import { formatDate } from './../../utils';
import { useAppContext } from '../../lib/contextLib';
import { makeGetRequest } from '../../lib/requestLib';
import { ErrorComponent } from '../../lib/errorLib';

const formatHistoryItem = (item) => {
    return isNil(item) ? null : <Row>
        <Col sm={6}>
            <div className="ms-2 me-auto">
                <div className="fw-bold"><FormattedMessage id="date" defaultMessage="Date" /></div>
                {formatDate(item.date)}
            </div>
        </Col>
        <Col sm={6}>
            <div className="ms-2 me-auto">
                <div className="fw-bold"><FormattedMessage id="action" defaultMessage="Action" /></div>
                {item.action}
            </div>
        </Col>
        {!isEmpty(item.text) && <Col sm={12}>
            <div className="ms-2 me-auto">
                <div className="fw-bold"><FormattedMessage id="comment" defaultMessage="Comment" /></div>
                {item.text}
            </div>
        </Col>}
        {!isEmpty(item.action_data) && <Col sm={12}>
            <div className="ms-2 me-auto">
                <div className="fw-bold"><FormattedMessage id="Action data" defaultMessage="Action data" /></div>
                {item.action_data}
            </div>
        </Col>}
        <Col sm={12}>
            <div className="ms-2 me-auto">
                <div className="fw-bold"><FormattedMessage id="id" defaultMessage="ID" /></div>
                {item.id}
            </div>
        </Col>
    </Row>
}

const formatObjectHistory = (historyList, currentItem) => {
    return <Accordion defaultActiveKey={[currentItem.id]} alwaysOpen>
        {historyList.map((item, index) => 
            <Accordion.Item eventKey={item.id} key={`history-list-elem-${index}`}>
                <Accordion.Header>
                    {item.id === currentItem.id ? <FontAwesomeIcon icon={faStar} /> : null}
                    {formatDate(item.date)}
                </Accordion.Header>
                <Accordion.Body>{formatHistoryItem(item)}</Accordion.Body>
            </Accordion.Item>
        )}
    </Accordion>
}

function ViewModal({ show, onHide, elem }) {
    const [objectHistory, setObjectHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const { accessToken, isAuthenticated } = useAppContext();

    useEffect(() => {
        async function loadObjectHistory({ objectKey, objectId }) {
            return makeGetRequest({
                path: `/actions-history`,
                accessToken,
                queryParams: { objectKey, objectId, skipDateFilter: 1 }
            });
        }

        async function onLoad() {
            if (!show || !isAuthenticated) {
                return;
            }
            const requestParams = {};
            const keys = [ 'message_id', 'resource_id', 'component_id' ];
            for (let key of keys) {
                if (elem[key]) {
                    requestParams.objectKey = key;
                    requestParams.objectId = elem[key];
                    break;
                } 
            }
            setIsLoading(true);     
            try {
                const oh = await loadObjectHistory(requestParams);
                setObjectHistory(oh);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
        
        setObjectHistory([]);
        onLoad();

    }, [elem, show, isAuthenticated, accessToken]);

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="view-history-modal" centered >
            <Modal.Header closeButton>
                <Modal.Title id="edit-message-modal"><FormattedMessage id="View history record" defaultMessage="View history record" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isNil(error) ? null : <ErrorComponent error={error} />}
                <Row className="view-history-wrapper">
                    {isEmpty(objectHistory) 
                        ? formatHistoryItem(elem)
                        : formatObjectHistory(objectHistory, elem)
                    }
                </Row>
            </Modal.Body>
            <OverlayLoader isLoading={isLoading} />
        </Modal>
    );
}

export default ViewModal;
import React from 'react';
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { FormattedMessage } from "react-intl";

function Header({ isAuthenticated, handleLogout }) {
    return (
        <Navbar collapseOnSelect expand="md" defaultExpanded={false} bg="light" variant="light" fixed="top">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img alt="" src="/syos_logo.png" height="30" className="d-inline-block align-top me-2" />
                    <Navbar.Text>SyoS Operation</Navbar.Text>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    {isAuthenticated 
                        ? ( // "eventKey" prop is necessary to "collapseOnSelect" feature work
                            <Nav>
                                <Nav.Link as={Link} to="/messages" eventKey="1"><FormattedMessage id="messages" defaultMessage="Messages" /></Nav.Link>
                                <Nav.Link as={Link} to="/resources" eventKey="2"><FormattedMessage id="resources" defaultMessage="Resources" /></Nav.Link>
                                <Nav.Link as={Link} to="/history" eventKey="3"><FormattedMessage id="history" defaultMessage="History" /></Nav.Link>
                                <Nav.Link onClick={handleLogout} eventKey="4"><FormattedMessage id="logout" defaultMessage="Logout" /></Nav.Link>
                            </Nav>
                        )
                        : <></>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;